import React from 'react'
import tw from 'twin.macro'
import ImageLink from '@components/elements/ImageLink'

import logoAlldrink from '@images/handelspartner/logos/alldrink-logo.jpg'
import logoAllguth from '@images/handelspartner/logos/allguth-logo.jpg'
import logoEdeka from '@images/handelspartner/logos/edeka-logo.jpg'
import logoFinkbeiner from '@images/handelspartner/logos/finkbeiner-logo.jpg'
import logoGetraenkeFleischmann from '@images/handelspartner/logos/getraenke-fleischmann-logo.jpg'
import logoHolAb from '@images/handelspartner/logos/hol-ab-logo.png'
import logoKaufpark from '@images/handelspartner/logos/kaufpark-logo.jpg'
import logoLogoGetraenkefachmaerkte from '@images/handelspartner/logos/logo-getraenkefachmaerkte-logo.jpg'
import logoOrtererGetraenkemaerkte from '@images/handelspartner/logos/orterer-getraenkemaerkte-logo.jpg'
import logoRewe from '@images/handelspartner/logos/rewe-logo.jpg'
import logoTrinkUndSpare from '@images/handelspartner/logos/trink-und-spare-logo.jpg'
import logoKaufland from '@images/handelspartner/logos/kaufland-logo.jpg'
import logoGlobus from '@images/handelspartner/logos/globus-logo.jpg'
import logoGetraenkeHoffmann from '@images/handelspartner/logos/getraenke-hoffmann-logo.jpg'
import logoMeinReal from '@images/handelspartner/logos/mein-real-logo.jpg'

import logoKlaasUndKock from '@images/handelspartner/logos/klaas-und-kock-logo.jpg'
import logoTegut from '@images/handelspartner/logos/tegut-logo.jpg'
import logoFamilaNordost from '@images/handelspartner/logos/famila-nordost-logo.jpg'
import logoFamilaNordwest from '@images/handelspartner/logos/famila-nordwest-logo.jpg'
import logoHit from '@images/handelspartner/logos/hit-logo.jpg'
import logoLehmann from '@images/handelspartner/logos/lehmann-logo.jpg'

const Section = tw.section`grid grid-cols-2 gap-4 lg:grid-cols-3`

const HandelspartnerLogos = () => {
  return (
    <>
      <style jsx>{`
        .hp-logo-wrapper img {
          border-radius: 1rem;
        }
      `}</style>
      <Section className="hp-logo-wrapper">
        <ImageLink img={logoAlldrink} url="https://alldrink.de/" alt="Alldrink" />
        <ImageLink img={logoAllguth} url="https://www.allguth.de/" alt="Allguth" />
        <ImageLink img={logoEdeka} url="https://www.edeka.de/" alt="EDEKA" />
        <ImageLink img={logoFamilaNordost} url="https://www.famila-nordost.de/" alt="Famila Nordost" />
        <ImageLink img={logoFamilaNordwest} url="https://www.famila-nordwest.de/" alt="Famila Nordwest" />
        <ImageLink img={logoFinkbeiner} url="https://www.finkbeiner.biz/index.php?p=getraenkemarkt" alt="Finkbeiner" />
        <ImageLink img={logoGetraenkeFleischmann} url="https://getraenke-fleischmann.de/" alt="Getränke Fleischmann" />
        <ImageLink img={logoGetraenkeHoffmann} url="https://www.getraenke-hoffmann.de/" alt="Getränke Hoffmann" />
        <ImageLink img={logoGlobus} url="https://www.globus.de/" alt="GLOBUS" />
        <ImageLink img={logoHit} url="https://www.hit.de/" alt="HIT" />
        <ImageLink img={logoHolAb} url="https://www.holab.de/" alt="HOL’AB!" />
        <ImageLink img={logoKaufland} url="https://www.kaufland.de/" alt="Kaufland" />
        <img src={logoKaufpark} alt="Kaufpark" />
        <ImageLink img={logoKlaasUndKock} url="https://www.klaas-und-kock.de/" alt="Klaas & Kock" />
        <ImageLink img={logoLehmann} url="https://www.horst-lehmann.de/" alt="Lehmann Getränke" />
        <ImageLink img={logoLogoGetraenkefachmaerkte} url="https://www.logo-getraenke.de/" alt="Logo Getränke Fachmarkt" />
        <ImageLink img={logoMeinReal} url="https://www.meinreal.de/" alt="mein real" />
        <ImageLink img={logoOrtererGetraenkemaerkte} url="https://www.orterer.de/" alt="Orterer Getränke-Märkte" />
        <ImageLink img={logoRewe} url="https://www.rewe.de/suche/?search=erdinger+brauhaus#products" alt="REWE" />
        <ImageLink img={logoTegut} url="https://www.tegut.com/" alt="Tegut" />
        <ImageLink img={logoTrinkUndSpare} url="https://trink-und-spare.de/" alt="Trink & Spare" />
      </Section>
    </>
  )
}

export default HandelspartnerLogos
