import React from 'react'

const ImageLink = ({ url, img, alt }) => {
  return (
    <a href={url} target="blank">
      <img src={img} alt={alt} />
    </a>
  )
}

export default ImageLink
